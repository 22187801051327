import axios, { AxiosRequestConfig } from 'axios';
import { extend } from 'lodash';

import { AnyObject } from './types';

declare module 'axios' {
	export interface AxiosRequestConfig {
		suppressToken?: boolean;
	}
}

let requestLocale = 'en';

export function setLocale(locale: string) {
	requestLocale = locale;
}

export enum ApiMethod {
	POST = 'post',
	GET = 'get',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
}

export enum ApiUploadMethod {
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
}

export interface ApiUploadOptions {
	onUploadProgress?: (progressEvent: any) => void;
	method?: ApiUploadMethod;
	params?: AnyObject;
	config?: AxiosRequestConfig;
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.https://iam-op.com/api;
axios.defaults.timeout = Number(process.env.VUE_APP_TIMEOUT);
axios.defaults.headers.common = {
	Accept: 'application/json, multipart/form-data',
};
axios.interceptors.request.use(
	config => {
		config.headers['Accept-Language'] = requestLocale;

		return config;
	},
	error => Promise.reject(error),
);

export default {
	get<R = any>(url: string, config?: AxiosRequestConfig) {
		url = normalizeUrl(url);

		return axios
			.get<R>(url, config)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},

	post<R = any>(url: string, data?: any, config?: AxiosRequestConfig) {
		url = normalizeUrl(url);

		return axios
			.post<R>(url, data, config)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},

	put<R = any>(url: string, data?: any, config?: AxiosRequestConfig) {
		url = normalizeUrl(url);

		return axios
			.put<R>(url, data, config)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},

	patch<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
		url = normalizeUrl(url);

		if (data instanceof FormData) {
			data.append('_method', 'PATCH');

			return this.post(url, data, config);
		}

		return axios
			.patch<R>(url, data, config)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},

	delete<R = any>(url: string, config?: AxiosRequestConfig) {
		url = normalizeUrl(url);

		return axios
			.delete<R>(url, config)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},

	upload(url: string, inputName: string, files: File | File[], options: ApiUploadOptions = {}) {
		const { params = {}, method = ApiUploadMethod.POST, config = {}, onUploadProgress } = options;
		const uploadConfig = extend(config, { onUploadProgress });
		const data = new FormData();

		Object.entries(params).forEach(([key, value]) => data.append(key, value));

		if (!Array.isArray(files)) {
			files = [files];
		}

		files.forEach(file => data.append(inputName, file));
		url = normalizeUrl(url);

		return axios[method](url, data, uploadConfig)
			.then(response => Promise.resolve(response.data))
			.catch(error => Promise.reject(error));
	},
};

function normalizeUrl(url: string) {
	return url.replace(/^\//gm, '');
}
